const TRIAL_XP_1_MONTH = {
  trialLength: 3,
  paidTrialMonths: 1,
  signupTypes: ['paid_trial_experience'],
};

export const usePaidTrialLengthPromotion = () => {
  const {trialLength, paidTrialMonths, signupTypes} = TRIAL_XP_1_MONTH;

  const experimentOverrideKeys = [
    {
      key: 'trialLength',
      value: trialLength,
    },
    {
      key: 'paidTrialMonths',
      value: paidTrialMonths,
    },
  ];

  return {
    trialLength,
    paidTrialMonths,
    experimentOverrideKeys,
    signupTypes,
  };
};
