// This file is auto generated by Verdict. Do not edit manually.
export type Experiment<T> = T & {__brand: 'Experiment'};

function experiment<ExperimentType>(eValue: ExperimentType) {
  return eValue as Experiment<ExperimentType>;
}

export const EXP_SIGNUP_PAID_TRIAL_MESSAGE = experiment(
  'e_a2731f189682c200baee9f805cf83ce7',
);
export const EXP_BROCHURE_EXPERIMENT_E2E_VALIDATION = experiment(
  'e_0a4da4809a7bdc36a0469a5f8586961b',
);
export const EXP_THREE_TILE_ON_PLUS_HOMEPAGE = experiment(
  'e_87a6bf7a5396c2a47e20fb87a901a16d',
);
export const EXP_MONETIZATION_NEW_TRIAL_XP_PAID_TRIAL_LENGTH = experiment(
  'e_cd7be1477be7a4d10cf2b6059210bb8d',
);
export const EXP_ALL_MULTITRACK_TOKENS_TO_2023_Q3_HOLDOUT = experiment(
  'e_e84f718448393ade1eb9cc62968a5ad7',
);
export const EXP_ADD_PLUS_CTA_TO_FREE_TRIAL_FOR_BRANDED_SEARCH_TRAFFIC =
  experiment('e_67bb086b8f39740bb72c25d0d806df2a');
export const EXP_ACQUISITION_INCENTIVE_V2_US = experiment(
  'e_97792bd591848e1bc8a25b537414d67e',
);
export const EXP_ACQUISITION_INCENTIVE_V2_ROW = experiment(
  'e_ed50f065b7fc6314a27446f3f7407379',
);
export const EXP_PLUS_NAV_STICKY_TEXT_TO_BUTTON = experiment(
  'e_2aca1c5ab64304f2052bb0584fdf47b2',
);
export const EXP_BNG_REDESIGN = experiment(
  'e_bcbf1ba25ba95e23eaa604b8e3569550',
);
export const EXP_PRESSINGLY_WEARY_CREEPER = experiment(
  'e_6d606793c97081febb5bbb4dc9457677',
);
export const EXP_GUSTILY_SOLLAR_GABS = experiment(
  'e_739680e8592d4675f97cc5c856ce6951',
);
export const EXP_RETAIL_MAIN_CTAS_COPIES = experiment(
  'e_499fc2d620814ed2fd9e99ba64d34ae5',
);
export const EXP_MWEB_APP_BANNER_BROCHURE_1721770869469 = experiment(
  'e_b38730879c59122800c23353261fafa0',
);
export const EXP_RETAIL_INCENTIVE_BUNDLE = experiment(
  'e_5cd443e08f73f41db81f8efa98afb32d',
);
export const EXP_ONEFIELD_REMOVAL_FREETRIAL_MOBILE = experiment(
  'e_ba592f5e0056bf3a15b5341492c569ee',
);
export const EXP_HOMEPAGEH124 = experiment(
  'e_28f2e1078dcc0b8bdd12f80abf485e40',
);
export const EXP_LOGOUT_PAGE_REDESIGN_APR24 = experiment(
  'e_b7871eff780cf105db2dee6d29098b45',
);
export const EXP_INTERNATIONAL_ACQUISITION_INCENTIVE = experiment(
  'e_573cb5af10f85a8c867ebc1181deca99',
);
export const EXP_FEATURED_IMAGE_REMOVAL_BLOG = experiment(
  'e_0493abd52c40b4032afe7b11ca9daa95',
);
export const EXP_FEATURED_IMAGE_REMOVAL_CORE_BLOG = experiment(
  'e_630bb8854c3fd21ad2e31f252b4e4179',
);
export const EXP_UPMARKET_CTA_ON_SHOPIFY_HOMEPAGE_APR24 = experiment(
  'e_ce8ac9de289fb75bd8b07d8a8d159886',
);
export const EXP_COGNISABLY_SANGUINE_CLAMPERS = experiment(
  'e_fcf68d5d6b874bfdc91004f13b89cfc8',
);
export const EXP_SIMPLIFIED_HEADER_MESSAGING = experiment(
  'e_8dd2435d50553db2fa714645d4a34294',
);
export const EXP_FREETRIAL_VIDEO_EXP = experiment(
  'e_5f0936980b25ac5e1afea671ad21bbed',
);
export const EXP_FEATUREGRIDEXP = experiment(
  'e_03b1022bdc0ad66c95401e980ac20b5d',
);
export const EXP_REMOVE_ONE_FIELD_COMMERCE_COACH_DESKTOP = experiment(
  'e_1d8e11dcb5feafc669b01ec62865f9a7',
);
export const EXP_REMOVE_ONE_FIELD_COMMERCE_COACH_MOBILE = experiment(
  'e_8888b54c42b589c076541948a1e5ba99',
);
export const EXP_GMV_REWARDS_ACQUISITION_INCENTIVE = experiment(
  'e_fa6e372333bbaebaa81e8ed54b5086d6',
);
export const EXP_LEARN_MORE_PRICING_HEADER_ALL_EN_LOCALES = experiment(
  'e_6dd0fe9a0f76e30a81329ff33d630f52',
);
export const EXP_SECONDARY_CTA_ON_INTL_BLOG = experiment(
  'e_3fbfe0304091167441ebe6fe3cbb2ee4',
);
export const EXP_JP_HOME_NEW_MESSAGING = experiment(
  'e_45eea39e61c06bf5715cb19b2217cfc5',
);
export const EXP_LOGIN_PAGE_REDESIGN = experiment(
  'e_0f0826dd96d8e85199538e21bccbc03e',
);
export const EXP_VALUE_PROP_DESIGN = experiment(
  'e_87ea188286e25c0ce45be275a47bd75f',
);
export const EXP_JP_HOME_PAGE_REDESIGN = experiment(
  'e_6d0601353576859d0a943d9b3c71dd36',
);
export const EXP_JAPAN_BROCHURE_DOWNLOAD = experiment(
  'e_9b952092e62cda054b5697ffc4edd799',
);
export const EXP_PRICING_DESKTOP_NO_ONE_F = experiment(
  'e_d3860248e9f695a55d9acec542e38e2a',
);
export const EXP_PRICING_MOBILE_NO_ONE_F = experiment(
  'e_ff1cde8fa78be5d9382108fae06dcf08',
);
export const EXP_RETAIL_UNIFIED_FUNNEL = experiment(
  'e_d0d0e51eac0743851808697b70d082eb',
);
export const EXP_NEW_DEST_FOR_COMMERCE_COACH_TRAFFIC = experiment(
  'e_a7e11b406ba7b0e6dc870479f89a4d2f',
);
export const EXP_TAILORED_MESSAGING_TOPTHREE_CTAS = experiment(
  'e_4baa7f150da000bb0e3544746100dbb7',
);
export const EXP_FREE_TRIAL_ON_THANK_YOU_PAGE_FOR_UNDER_5M_NEW = experiment(
  'e_95aee75036a3657f251003e2cc4c49cf',
);
export const EXP_DROPSHIPPING_PERSONALIZATION = experiment(
  'e_70bf1b0c16f292230ee6d5186327c032',
);
export const EXP_MARKETS_PLUS_V_CORE = experiment(
  'e_8e0273c7774531761fc6564fd1703474',
);
export const EXP_PLUS_B2B_ENTERPRISE_B2B = experiment(
  'e_21a654cb5c76b34b7b805df2310faaa1',
);
export const EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_HEADER_BANNER_NON_CORE_EN_BLOG =
  experiment('e_3a92ada9f927f2b7452adc5f04c5aa4c');
export const EXP_HERO_IMAGE_EXP = experiment(
  'e_ebf678e5d3e370de548c1a850743ed18',
);
export const EXP_PRICING_CTA_TEST_MOBILE_NON_CORE_EN_FR_DE = experiment(
  'e_4222304478c6b25932dc4a1086db3e69',
);
export const EXP_PRICING_CTA_DESKTOP_NON_CORE_EN_DE_FR = experiment(
  'e_76b58aad5d41adfefd7f79314525dd94',
);
export const EXP_PLUS_NAV_LIMIT_PAGES = experiment(
  'e_a0c2c1de6b048138a2958d2b43ccf2a7',
);
export const EXP_LEARN_MORE_CTA_PRICING_DESKTOP = experiment(
  'e_a8bb96acc17b90e009e1933a1b0fad2a',
);
export const EXP_LEARN_MORE_CTA_PRICING_MOBILE = experiment(
  'e_ced850d6b58b08960aaf9c31ec571326',
);
export const EXP_JP_HOME_HERO_CTA = experiment(
  'e_3f6de0a544300b7fc47d2103be14f33e',
);
export const EXP_JP_HOME_HERO_CTA_MOBILE = experiment(
  'e_fc8355a21e34422987772230e8f3042b',
);
export const EXP_MOBILE_INDEXABLE_LOGIN_TEST = experiment(
  'e_e669a3c9f4f817e14c588b679c379d8f',
);
export const EXP_DESKTOP_INDEXABLE_LOGIN_TEST_1723823754179 = experiment(
  'e_6c1cc04967f7e2828c53607c2d82278f',
);
export const EXP_JP_BLOG_DOCUMENT_DOWNLOAD_CTA = experiment(
  'e_a043f92a7b05d77813f49b9468e26a61',
);
export const EXP_SLOGAN_MAKER_TEXT = experiment(
  'e_ed06cdee3a89ed4c8be33df253c151a4',
);
export const EXP_ADDING_SALES_CTAS = experiment(
  'e_a0dc173f1f3386925a6b589f76fa09c5',
);
export const EXP_RETAIL_SIGNUP_TYPES = experiment(
  'e_19ffdf044d8d64112c8d25e39e6757f6',
);

type StrongTypeExperiments =
  | typeof EXP_SIGNUP_PAID_TRIAL_MESSAGE
  | typeof EXP_BROCHURE_EXPERIMENT_E2E_VALIDATION
  | typeof EXP_THREE_TILE_ON_PLUS_HOMEPAGE
  | typeof EXP_MONETIZATION_NEW_TRIAL_XP_PAID_TRIAL_LENGTH
  | typeof EXP_ALL_MULTITRACK_TOKENS_TO_2023_Q3_HOLDOUT
  | typeof EXP_ADD_PLUS_CTA_TO_FREE_TRIAL_FOR_BRANDED_SEARCH_TRAFFIC
  | typeof EXP_ACQUISITION_INCENTIVE_V2_US
  | typeof EXP_ACQUISITION_INCENTIVE_V2_ROW
  | typeof EXP_PLUS_NAV_STICKY_TEXT_TO_BUTTON
  | typeof EXP_BNG_REDESIGN
  | typeof EXP_PRESSINGLY_WEARY_CREEPER
  | typeof EXP_GUSTILY_SOLLAR_GABS
  | typeof EXP_RETAIL_MAIN_CTAS_COPIES
  | typeof EXP_MWEB_APP_BANNER_BROCHURE_1721770869469
  | typeof EXP_RETAIL_INCENTIVE_BUNDLE
  | typeof EXP_ONEFIELD_REMOVAL_FREETRIAL_MOBILE
  | typeof EXP_HOMEPAGEH124
  | typeof EXP_LOGOUT_PAGE_REDESIGN_APR24
  | typeof EXP_INTERNATIONAL_ACQUISITION_INCENTIVE
  | typeof EXP_FEATURED_IMAGE_REMOVAL_BLOG
  | typeof EXP_FEATURED_IMAGE_REMOVAL_CORE_BLOG
  | typeof EXP_UPMARKET_CTA_ON_SHOPIFY_HOMEPAGE_APR24
  | typeof EXP_COGNISABLY_SANGUINE_CLAMPERS
  | typeof EXP_SIMPLIFIED_HEADER_MESSAGING
  | typeof EXP_FREETRIAL_VIDEO_EXP
  | typeof EXP_FEATUREGRIDEXP
  | typeof EXP_REMOVE_ONE_FIELD_COMMERCE_COACH_DESKTOP
  | typeof EXP_REMOVE_ONE_FIELD_COMMERCE_COACH_MOBILE
  | typeof EXP_GMV_REWARDS_ACQUISITION_INCENTIVE
  | typeof EXP_LEARN_MORE_PRICING_HEADER_ALL_EN_LOCALES
  | typeof EXP_SECONDARY_CTA_ON_INTL_BLOG
  | typeof EXP_JP_HOME_NEW_MESSAGING
  | typeof EXP_LOGIN_PAGE_REDESIGN
  | typeof EXP_VALUE_PROP_DESIGN
  | typeof EXP_JP_HOME_PAGE_REDESIGN
  | typeof EXP_JAPAN_BROCHURE_DOWNLOAD
  | typeof EXP_PRICING_DESKTOP_NO_ONE_F
  | typeof EXP_PRICING_MOBILE_NO_ONE_F
  | typeof EXP_RETAIL_UNIFIED_FUNNEL
  | typeof EXP_NEW_DEST_FOR_COMMERCE_COACH_TRAFFIC
  | typeof EXP_TAILORED_MESSAGING_TOPTHREE_CTAS
  | typeof EXP_FREE_TRIAL_ON_THANK_YOU_PAGE_FOR_UNDER_5M_NEW
  | typeof EXP_DROPSHIPPING_PERSONALIZATION
  | typeof EXP_MARKETS_PLUS_V_CORE
  | typeof EXP_PLUS_B2B_ENTERPRISE_B2B
  | typeof EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_HEADER_BANNER_NON_CORE_EN_BLOG
  | typeof EXP_HERO_IMAGE_EXP
  | typeof EXP_PRICING_CTA_TEST_MOBILE_NON_CORE_EN_FR_DE
  | typeof EXP_PRICING_CTA_DESKTOP_NON_CORE_EN_DE_FR
  | typeof EXP_PLUS_NAV_LIMIT_PAGES
  | typeof EXP_LEARN_MORE_CTA_PRICING_DESKTOP
  | typeof EXP_LEARN_MORE_CTA_PRICING_MOBILE
  | typeof EXP_JP_HOME_HERO_CTA
  | typeof EXP_JP_HOME_HERO_CTA_MOBILE
  | typeof EXP_MOBILE_INDEXABLE_LOGIN_TEST
  | typeof EXP_DESKTOP_INDEXABLE_LOGIN_TEST_1723823754179
  | typeof EXP_JP_BLOG_DOCUMENT_DOWNLOAD_CTA
  | typeof EXP_SLOGAN_MAKER_TEXT
  | typeof EXP_ADDING_SALES_CTAS
  | typeof EXP_RETAIL_SIGNUP_TYPES;

export const ALL_EXPERIMENTS = [
  'e_a2731f189682c200baee9f805cf83ce7',
  'e_0a4da4809a7bdc36a0469a5f8586961b',
  'e_87a6bf7a5396c2a47e20fb87a901a16d',
  'e_cd7be1477be7a4d10cf2b6059210bb8d',
  'e_e84f718448393ade1eb9cc62968a5ad7',
  'e_67bb086b8f39740bb72c25d0d806df2a',
  'e_97792bd591848e1bc8a25b537414d67e',
  'e_ed50f065b7fc6314a27446f3f7407379',
  'e_2aca1c5ab64304f2052bb0584fdf47b2',
  'e_bcbf1ba25ba95e23eaa604b8e3569550',
  'e_6d606793c97081febb5bbb4dc9457677',
  'e_739680e8592d4675f97cc5c856ce6951',
  'e_499fc2d620814ed2fd9e99ba64d34ae5',
  'e_b38730879c59122800c23353261fafa0',
  'e_5cd443e08f73f41db81f8efa98afb32d',
  'e_ba592f5e0056bf3a15b5341492c569ee',
  'e_28f2e1078dcc0b8bdd12f80abf485e40',
  'e_b7871eff780cf105db2dee6d29098b45',
  'e_573cb5af10f85a8c867ebc1181deca99',
  'e_0493abd52c40b4032afe7b11ca9daa95',
  'e_630bb8854c3fd21ad2e31f252b4e4179',
  'e_ce8ac9de289fb75bd8b07d8a8d159886',
  'e_fcf68d5d6b874bfdc91004f13b89cfc8',
  'e_8dd2435d50553db2fa714645d4a34294',
  'e_5f0936980b25ac5e1afea671ad21bbed',
  'e_03b1022bdc0ad66c95401e980ac20b5d',
  'e_1d8e11dcb5feafc669b01ec62865f9a7',
  'e_8888b54c42b589c076541948a1e5ba99',
  'e_fa6e372333bbaebaa81e8ed54b5086d6',
  'e_6dd0fe9a0f76e30a81329ff33d630f52',
  'e_3fbfe0304091167441ebe6fe3cbb2ee4',
  'e_45eea39e61c06bf5715cb19b2217cfc5',
  'e_0f0826dd96d8e85199538e21bccbc03e',
  'e_87ea188286e25c0ce45be275a47bd75f',
  'e_6d0601353576859d0a943d9b3c71dd36',
  'e_9b952092e62cda054b5697ffc4edd799',
  'e_d3860248e9f695a55d9acec542e38e2a',
  'e_ff1cde8fa78be5d9382108fae06dcf08',
  'e_d0d0e51eac0743851808697b70d082eb',
  'e_a7e11b406ba7b0e6dc870479f89a4d2f',
  'e_4baa7f150da000bb0e3544746100dbb7',
  'e_95aee75036a3657f251003e2cc4c49cf',
  'e_70bf1b0c16f292230ee6d5186327c032',
  'e_8e0273c7774531761fc6564fd1703474',
  'e_21a654cb5c76b34b7b805df2310faaa1',
  'e_3a92ada9f927f2b7452adc5f04c5aa4c',
  'e_ebf678e5d3e370de548c1a850743ed18',
  'e_4222304478c6b25932dc4a1086db3e69',
  'e_76b58aad5d41adfefd7f79314525dd94',
  'e_a0c2c1de6b048138a2958d2b43ccf2a7',
  'e_a8bb96acc17b90e009e1933a1b0fad2a',
  'e_ced850d6b58b08960aaf9c31ec571326',
  'e_3f6de0a544300b7fc47d2103be14f33e',
  'e_fc8355a21e34422987772230e8f3042b',
  'e_e669a3c9f4f817e14c588b679c379d8f',
  'e_6c1cc04967f7e2828c53607c2d82278f',
  'e_a043f92a7b05d77813f49b9468e26a61',
  'e_ed06cdee3a89ed4c8be33df253c151a4',
  'e_a0dc173f1f3386925a6b589f76fa09c5',
  'e_19ffdf044d8d64112c8d25e39e6757f6',
] as StrongTypeExperiments[];

type ExtractString<T> = T extends Experiment<infer S> ? S : never;

export type Experiments =
  | StrongTypeExperiments
  | ExtractString<StrongTypeExperiments>;
