import {useContext} from 'react';

import {usePageData} from '@/components/shared/Page/PageData';
import type {Experiments} from '@/experiments';
import {VerdictLookupContext} from '@/VerdictLookupContext';

/**
 * Returns the experiment variant for the given experiment handle.
 */
export const useExperiment = (
  experimentHandle: Experiments,
): string | undefined => {
  const verdictLookups = useContext(VerdictLookupContext);
  verdictLookups.add(experimentHandle);

  const {experimentVariationId = ''} = usePageData();

  if (!experimentVariationId) return;

  const experiment = experimentVariationId.split(',').find((exp: string) => {
    const [handle] = exp.split(':');
    return handle === experimentHandle;
  });

  if (experiment) {
    const [, variant] = experiment.split(':');
    return variant && variant !== 'null' ? variant : undefined;
  }
};
